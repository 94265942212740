import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PieChart } from "react-minimal-pie-chart";
import { formatNumber } from "./utils";

import { getColor } from "./colors";

const Container = styled.div`
  flex: 1;
  display: flex;
  margin-top: 1rem;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const BudgetInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Name = styled.h1`
  font-weight: normal;
  font-size: 4rem;
  margin: 0;
  padding-bottom: 1rem;

  @media (max-width: 700px) {
    font-size: 3rem;
  }
`;

const TotalBudget = styled.h2`
  font-weight: normal;
  margin: 0;
`;
const TotalBudgetNumber = styled.h2`
  font-size: 3rem;
  margin: 0;
  margin-bottom: 1rem;

  @media (max-width: 700px) {
    font-size: 2rem;
  }
`;

const PoliceBudget = styled.h2`
  font-weight: normal;
  color: var(--color-primary);
  margin: 0;
`;

const PoliceBudgetNumber = styled.h2`
  font-size: 3.5rem;
  color: var(--color-primary);
  margin: 0;
  margin-left: 1rem;

  @media (max-width: 700px) {
    margin-left: 0.5rem;
    font-size: 2.5rem;
  }
`;

const ChartContainer = styled.div`
  flex: 1;
  max-height: 50vh;
  padding: 2rem;
  text-align: right;

  @media (max-width: 700px) {
    max-height: none;
    padding: 0;
    padding-bottom: 4rem;
    margin: 1.5rem;
    text-align: left;
  }
`;

const Source = styled.a`
  color: var(--color-foreground);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: var(--color-foreground);
  }

  @media (max-width: 700px) {
    padding-top: 1rem;
  }
`;

export type City = {
  name: string;
  state: string;
  abbreviation?: string;
  totalBudget: number;
  policeBudget: number;
  percentage: number;
  source: string;
};

const formatBudgetData = (city: City) => [
  {
    title: "everything else",
    value: city.totalBudget - city.policeBudget,
    color: "lightgrey",
  },
  {
    title: "police",
    value: city.policeBudget,
    color: getColor("primary"),
  },
];

export default ({ city }: { city: City }) => {
  const [smallLabel, setSmallLabel] = useState(false);
  const shiftSize = 4;

  useEffect(() => setSmallLabel(city.percentage < 24), [city.percentage]);

  const labelHack = ({
    dataEntry,
  }: {
    dataEntry: { title?: any; percentage: number };
  }) => {
    if (dataEntry.title === "police") {
      return Math.round(dataEntry.percentage) + "%";
    }

    return null;
  };

  return (
    <Container>
      <BudgetInfo>
        <div>
          <Name>{`${city.name}, ${city.state}`}</Name>
          <TotalBudget>{`total budget:`}</TotalBudget>
          <TotalBudgetNumber>{`$${formatNumber(
            city.totalBudget
          )}`}</TotalBudgetNumber>
          <PoliceBudget>{`police budget:`}</PoliceBudget>
          <PoliceBudgetNumber>
            {`$${formatNumber(city.policeBudget)}`}
          </PoliceBudgetNumber>
        </div>
      </BudgetInfo>
      <ChartContainer>
        <PieChart
          animate
          data={formatBudgetData(city)}
          radius={PieChart.defaultProps.radius - shiftSize}
          segmentsShift={(index) => (index === 1 ? shiftSize : 0.5)}
          label={labelHack}
          labelPosition={smallLabel ? 80 : undefined}
          labelStyle={{
            fontSize: smallLabel ? 10 : 14,
            fill: getColor("background"),
          }}
          totalValue={city.totalBudget}
        />
        <Source href={city.source} target="_blank">
          Annual spending per latest available approved budget (source)
        </Source>
      </ChartContainer>
    </Container>
  );
};
