import React, { useState } from "react";
import Autosuggest, { Theme } from "react-autosuggest";
import styled from "styled-components";

import CityBudget, { City } from "./City";
import { getColor } from "./colors";
import { formatNumber } from "./utils";

import config from "./config.json";
import data from "./ftp_budgets.json";
import logo from "./ACRE-logo.png";

const Container = styled.div<{ gradient?: string }>`
  background: var(--color-background);
  color: var(--color-foreground);
  background-image: ${(props) => props.gradient};
  padding-top: 2rem;
`;

const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem;
  color: var(--color-foreground);

  @media (max-width: 700px) {
    flex-direction: column;
    padding: 0.5rem 0;
  }
`;

const ImageLogo = styled.img`
  width: 12rem;
`;

const Description = styled.div`
  font-size: 2rem;
  padding: 1rem;
  margin-left: 1rem;
  line-height: 1.8rem;

  @media (max-width: 700px) {
    font-size: 1.5rem;
    padding: 1.5rem 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  margin: 0.5rem;

  @media (max-width: 700px) {
    padding: 0.5rem;
    padding-bottom: 2rem;
  }
`;

const Suggestion = styled.p`
  font-size: 2rem;
`;

const CityPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const DisplayModeSelector = styled.div`
  border: 1px solid var(--color-foreground);
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1.5rem;
  /* height: 100%; */
  padding: 0.8rem;
`;

const DisplayModeOption = styled.div`
  user-select: none;
`;

const ListView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  h2 {
    color: var(--color-primary);
  }

  li {
    padding: 5px;
    color: var(--color-foreground-level-1);
    cursor: pointer;
  }

  li:hover {
    color: var(--color-foreground);
  }
`;

const MetricHeader = styled.th`
  text-align: right;
  text-align: center;
  font-size: 1rem;
  color: var(--color-secondary);

  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileMetricHeader = styled(MetricHeader)`
  text-align: right;
  display: none;

  @media (max-width: 600px) {
    display: table-cell;
  }
`;

const CityRow = styled.tr`
  cursor: pointer;
  :hover {
    color: var(--color-foreground-level-1);
  }
`;
const CityCell = styled.td`
  text-align: left;
  padding-left: 20px;
  width: 100%;
`;

const MetricCell = styled.td`
  text-align: right;

  @media (max-width: 600px) {
    display: none;
  }
`;

const PercentCell = styled(MetricCell)`
  text-align: right;

  @media (max-width: 600px) {
    display: table-cell;
  }
`;

const Table = styled.table`
  width: 100%;

  @media (min-width: 400px) {
    max-width: 80%;
  }
`;

const cities: City[] = (data as any).map((city: any) => {
  return {
    name: city.name.split("[")[0],
    state: city.state,
    abbreviation: city.abbreviation,
    totalBudget: parseFloat(city.totalBudget.replace(/\$|,/g, "")),
    policeBudget: parseFloat(city.policeBudget.replace(/\$|,/g, "")),
    percentage: parseFloat(city.percentage.replace(/%|,/g, "")),
    source: city.source,
  };
});

const getSuggestions = (value: string) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : cities.filter(
        (city) =>
          city.name.toLowerCase().slice(0, inputLength) === inputValue ||
          city.abbreviation?.toLowerCase().slice(0, inputLength) ===
            inputValue ||
          city.state.toLowerCase().slice(0, inputLength) === inputValue
      );
};

const getSuggestionValue = (suggestion: City) => suggestion.name;

const getCity = (name: string) =>
  cities.find(
    (city) =>
      city.name === name ||
      city.name.toLowerCase() === name ||
      city.abbreviation === name ||
      city.abbreviation?.toLowerCase() === name
  );

const renderSuggestion = (suggestion: City) => (
  <Suggestion>{`${suggestion.name}, ${suggestion.state}`}</Suggestion>
);

const theme: Theme = {
  container: {
    textAlign: "center",
    flex: 1,
  },
  inputFocused: {
    background: getColor("background"),
  },
  input: {
    border: `1px solid ${getColor("foreground")}`,
    borderRadius: 4,
    outlineColor: getColor("foreground"),
    fontSize: "1.5rem",
    fontFamily: "Gotham Medium",
    padding: "1rem",
    margin: 0,
    width: "100%",
    boxSizing: "border-box",
    background: getColor("background"),
    color: getColor("secondary"),
  },
  suggestionsList: {
    listStyle: "none",
    textAlign: "left",
    cursor: "pointer",
    paddingLeft: "0.5rem",
  },
  suggestionHighlighted: {
    background: getColor("foreground-level-1"),
  },
};

enum DisplayMode {
  Search = "Search",
  List = "View All",
}

function App() {
  const [value, setValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<City[]>([]);
  const [city, setCity] = useState<City | undefined>(
    getCity(config.defaultCity)
  );
  const [displayMode, setDisplayMode] = useState<DisplayMode>(
    DisplayMode.Search
  );

  const toggleDisplayMode = () =>
    setDisplayMode(
      displayMode === DisplayMode.Search ? DisplayMode.List : DisplayMode.Search
    );

  const onChange = (_: any, { newValue }: { newValue: string }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Enter a city",
    value,
    onChange,
  };

  const onSuggestionSelected = (
    _: React.FormEvent,
    {
      suggestion,
    }: {
      suggestion: City;
    }
  ) => {
    setCity(suggestion);
  };

  const selectFromListView = (city: City) => {
    return function () {
      toggleDisplayMode();

      setCity(city);
    };
  };

  const renderCityPicker = (displayMode: DisplayMode) => {
    if (displayMode === DisplayMode.List) {
      const groupedCities = cities.reduce((r: any, city: City) => {
        if (r[city.state] === undefined) {
          r[city.state] = [];
        }
        r[city.state].push(city);
        return r;
      }, {});

      return (
        <ListView>
          <Table>
            {Object.keys(groupedCities)
              .sort()
              .map((stateName: string, stateIndex: number) => {
                return (
                  <tbody key={stateIndex}>
                    <tr>
                      <td>
                        <h2>{stateName}</h2>
                      </td>
                      {stateIndex === 0 && (
                        <>
                          <MetricHeader>Police Budget</MetricHeader>
                          <MetricHeader>Total Budget</MetricHeader>
                          <MetricHeader>Percent</MetricHeader>
                          <MobileMetricHeader>
                            Police&nbsp;Budget (%&nbsp;of&nbsp;Total)
                          </MobileMetricHeader>
                        </>
                      )}
                    </tr>
                    {groupedCities[stateName].map(
                      (city: City, index: number) => {
                        const percentage = Math.round(city.percentage);

                        return (
                          <CityRow
                            onClick={selectFromListView(city)}
                            key={index}
                          >
                            <CityCell>{city.name}</CityCell>
                            <MetricCell>
                              ${formatNumber(city.policeBudget)}
                            </MetricCell>
                            <MetricCell>
                              ${formatNumber(city.totalBudget)}
                            </MetricCell>
                            <PercentCell>{percentage}%</PercentCell>
                          </CityRow>
                        );
                      }
                    )}
                  </tbody>
                );
              })}
          </Table>
        </ListView>
      );
    } else if (displayMode === DisplayMode.Search) {
      return (
        <Autosuggest
          theme={theme}
          suggestions={suggestions as readonly City[]}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      );
    }
  };

  return (
    <Container gradient={config.backgroundGradient}>
      <Header>
        <a href="https://acrecampaigns.org/issues/policing-and-incarceration/">
          <ImageLogo src={logo} />
        </a>
        <Description>
          City budgets belong to us. How do America's 300 biggest cities spend
          our tax dollars?
        </Description>
      </Header>
      <Content>
        <CityPickerContainer>
          {renderCityPicker(displayMode)}
          {displayMode === DisplayMode.Search && (
            <DisplayModeSelector onClick={toggleDisplayMode}>
              <DisplayModeOption>
                {displayMode === DisplayMode.Search
                  ? DisplayMode.List
                  : DisplayMode.Search}
              </DisplayModeOption>
            </DisplayModeSelector>
          )}
        </CityPickerContainer>
        {city && <CityBudget city={city} />}
      </Content>
    </Container>
  );
}

export default App;
